<template lang="pug">
.sign-in
  h1 Entrar
  .no-sign-in
    span Ainda não possui conta? &nbsp;
    Button(
      text="Clique aqui para se cadastrar"
      @click="doNavigateToSignUp"
    )
  .info
    label.label(
      for="input-email"
    ) Email

    input.input(
      id="input-email"
      type="email"
      placeholder="email@email.com"
      v-model="email"
    )

    label.label(
      for="input-password"
    ) Senha

    input.input(
      id="input-password"
      type="password"
      placeholder="password"
      v-model="password"
    )

    span.error(
      v-if="showError"
    ) Não foi possível fazer login, verifique seus dados

    Button(
      text="Entrar"
      border="base"
      @click="doSignIn"
    )
</template>

<script>
import {
  mapActions
} from 'vuex'

import {
  Button
} from '@/components/atoms'

export default {
  name: 'SignIn',
  data: () => ({
    email: '',
    password: '',
    showError: false
  }),
  components: {
    Button
  },
  methods: {
    ...mapActions('users',
      [
        'setSignIn'
      ]
    ),
    doNavigateToSignUp () {
      this.$router.push('/SignUp')
    },
    doSignIn () {
      const user = { email: this.email, password: this.password }
      this.setSignIn(user).then(resp => {
        if (!resp) {
          this.showError = true
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  .sign-in
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    .no-sign-in
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: 16px 0
      font-size: 16px
      font-weight: 300

    .info
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column

    .label
      text-align: left
      min-width: 250px
      max-width: 100%

    .input
      border: none
      min-width: 250px
      border-bottom: 1px solid #000
      margin: 16px 0 24px
      font-size: 14px
      min-width: 250px
      max-width: 100%

    .error
      font-size: 16px
      color: red
      margin: 8px 0 16px
</style>
